import 'piral/polyfills';
import { renderInstance } from 'piral';
import { layout, errors } from './layout';

// change to your feed URL here (either using feed.piral.cloud or your own service)
let instance = { core: null }
const location = window.location
console.log('location', location)
const { hostname, port, pathname } = location;
let projectList = [];
if (hostname === 'idataceshi.pvmedtech.com') {
  if (pathname.indexOf('web') > -1) {
    projectList = [
      { "name": "web-test", "version": "0.1.0", "link": `https://idataceshi.pvmedtech.com/frontend/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  } else if (pathname.indexOf('idata') > -1) {
    projectList = [
      { "name": "idata-test", "version": "0.1.0", "link": `https://idataceshi.pvmedtech.com/admin/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  }
} else if (hostname === 'idata.pvmedtech.com') {
  if (pathname.indexOf('web') > -1) {
    projectList = [
      { "name": "web-prod", "version": "0.1.0", "link": `https://idata.pvmedtech.com/frontend/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  } else if (pathname.indexOf('idata') > -1) {
    projectList = [
      { "name": "idata-prod", "version": "0.1.0", "link": `https://idata.pvmedtech.com/admin/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  }
} else if (hostname === '192.168.8.222' && port === '3002') {
  if (pathname.indexOf('web') > -1) {
    projectList = [
      { "name": "web-test", "version": "0.1.0", "link": `http://192.168.8.222:3003/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  } else if (pathname.indexOf('idata') > -1) {
    projectList = [
      { "name": "idata-test", "version": "0.1.0", "link": `http://192.168.8.222:3001/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  }
} else if (hostname === '192.168.18.190' && port === '3002') {
  if (pathname.indexOf('web') > -1) {
    projectList = [
      { "name": "web-dev", "version": "0.1.0", "link": `http://192.168.18.190:3003/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  } else if (pathname.indexOf('idata') > -1) {
    projectList = [
      { "name": "idata-dev", "version": "0.1.0", "link": `http://192.168.18.190:3001/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    ];
  }
} else if (hostname === '192.168.1.249' && port === '3002') {
  projectList = [
    { "name": "web-prod", "version": "0.1.0", "link": `http://192.168.1.249:3003/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
    { "name": "idata-prod", "version": "0.1.0", "link": `http://192.168.1.249:3001/index.js`, "spec": "v2", "requireRef": "webpackChunkpr_project", "dependencies": {} },
  ];
}
instance.core = renderInstance({
  layout: layout(instance),
  errors,
  requestPilets() {
    return Promise.resolve(projectList);
  },
});

console.log(instance);

