import * as React from 'react';
import { Piral } from 'piral'
import { ComponentsState, ErrorComponentsState, Menu, Notifications, SwitchErrorInfo, MenuItemProps } from 'piral';
import { Link } from 'react-router-dom';

const MenuItem: React.FC<MenuItemProps> = ({ children }) => <li className="nav-item">{children}</li>;

const defaultMenuItems = (
  <>
    <MenuItem type="general" meta={{}}>
    </MenuItem>
  </>
);

export const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className="error">无法找到当前路由</p>
      <p>
        <Link to="/">返回首页</Link>
      </p>
    </div>
  ),
};

export function layout(instance): Partial<ComponentsState> {
  return {
    LoadingIndicator: () => (<div style={{ background: '#141414', width: '100vw', height: '100vh' }}></div>),
    ErrorInfo: props => (
      <div>
        <h1>Error</h1>
        <SwitchErrorInfo {...props} />
      </div>
    ),
    DashboardContainer: (props) => {
      return (
        <div></div>
      )
    },
    Layout: ({ children }) => (
      <div>
        {children}
      </div>
    ),
    // DashboardTile: ({ columns, rows, children }) => <div className={`tile cols-${columns} rows-${rows}`}>{children}</div>,
    // MenuContainer: ({ children }) => {
    //   const [collapsed, setCollapsed] = React.useState(true);
    //   return (
    //     <header>
    //       <nav className="navbar navbar-light navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3">
    //         <div className="container">
    //           <Link className="navbar-brand" to="/">
    //             Piral
    //           </Link>
    //           <button
    //             aria-label="Toggle navigation"
    //             type="button"
    //             onClick={() => setCollapsed(!collapsed)}
    //             className="navbar-toggler mr-2">
    //             <span className="navbar-toggler-icon" />
    //           </button>
    //           <div
    //             className={`collapse navbar-collapse d-sm-inline-flex flex-sm-row-reverse ${collapsed ? '' : 'show'}`}
    //             aria-expanded={!collapsed}>
    //             <ul className="navbar-nav flex-grow">
    //               {children}
    //               {defaultMenuItems}
    //             </ul>
    //           </div>
    //         </div>
    //       </nav>
    //     </header>
    //   );
    // },
    // MenuItem,
    // NotificationsHost: ({ children }) => <div className="notifications">{children}</div>,
    // NotificationsToast: ({ options, onClose, children }) => (
    //   <div className={`notification-toast ${options.type}`}>
    //     <div className="notification-toast-details">
    //       {options.title && <div className="notification-toast-title">{options.title}</div>}
    //       <div className="notification-toast-description">{children}</div>
    //     </div>
    //     <div className="notification-toast-close" onClick={onClose} />
    //   </div>
    // ),
  };
} 